@import "../fonts/fonts.css";

:root {
  --gray: #f8f8f8;
  --grayE: #eee;
  --white: #fff;
  --link: #4169e1;
  --dark: #222;
  --darkmode: #14141b;
}

body {
  min-width: 100vw !important;
  max-width: 100vw !important;
  overflow-x: hidden !important;
  min-height: 100vh !important;
  height: fit-content;
  font-family: "Josefin Sans", sans-serif;
  color: #0f0e17 !important;
}

.global {
  min-height: 100vh;
  min-width: 100%;
  max-height: fit-content;
  overflow-x: hidden;
  padding: 5px;
}
.box {
  position: relative;
  display: flex;
  width: fit-content;
}
.logo,
.mobile-nav .logo {
  font-family: "Poiret One", cursive !important;
  letter-spacing: 10px;

}
.main {
  display: flex;
  justify-content: flex-end !important;
  min-width: 100vw !important;
  max-width: 100vw !important;
  min-height: 100vh !important;
}
.route {
  letter-spacing: 2px;
}
.app {
  width: 90%;
  height: 100%;
}
::-webkit-scrollbar {
  display: none !important;
}
.navbar {
  width: 10%;
  height: 100vh !important;
  background: white;
  z-index: 10;
  transition: width 0.4s ease;
}

.menu,
.menu::before,
.menu::after,
.mobile,
.mobile::after,
.mobile::before {
  content: "";
  background: var(--white);
  width: 30px;
  transition: all 0.4s ease;
  height: 1.5px;
  border-radius: 99px;
}
.menu::after,
.menu::before,
.mobile::after,
.mobile::before {
  position: absolute;
}
.menu::before,
.mobile::before {
  transform: translateY(7px);
}
.menu::after,
.mobile::after {
  transform: translateY(-7px);
}
.menu.pop::after,
.mobile.pop::after {
  transform: rotate(45deg) translate(35px, -35px);
}
.menu.pop::before,
.mobile.pop::before {
  transform: rotate(-45deg) translate(35px, 35px);
}
.menu.pop,
.mobile.pop {
  transform: translateX(-50px);
  background-color: transparent;
}

.popin {
  width: 90% !important;
  box-shadow: 0px 0px 5px 1px rgba($color: #000000, $alpha: 0.1);
}
.nav-left {
  flex: 0.2;
}
.nav-right {
  flex: 1;
  width: 100%;
  height: 100%;
}
.routes {
  min-width: 10%;
}
.route {
  transition: all 0.4s ease;
}
.route.active {
  color: var(--link);
}
.route {
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--dark);
}
.route::before {
  content: "";
  transition: all 0.2s ease !important;
  background-color: var(--dark);
  width: 0%;
  height: 2px;
}
.route.active::before {
  width: 30%;
}
.burger {
  right: -20px;
  top: 50vh;
  width: 50px;
  z-index: 100 !important;
  height: 50px;
  font-size: 24px;
  background-color: var(--dark);
  color: var(--white);
  border-radius: 50%;
  cursor: pointer;
}
.social-hanger {
  z-index: 9 !important;
}
.sm-icons {
  display: flex;
  align-items: center;
}
.sm-icons::after {
  content: "";
  background-color: var(--white);
  height: 20%;
  width: 2px;
  margin-top: 10px;
}
.user-nav,
.navbar .social {
  display: none;
}
.mobile-nav {
  display: none !important;
}
.about_image {
  min-height: 500px !important;
}
@media (max-width: 600px) {
  .routes {
    padding: 10px 0;
    z-index: 10 !important;
  }
  .overlay-text {
    text-align: center !important;
    width: 100% !important;
    .text-exl {
      font-size: 48px !important;
    }
    .text-xl {
      font-size: 24px;
    }
  }
  .color-shades,
  .social-hanger {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .app {
    width: 100%;
  }
  .mobile-nav.scroll {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.19);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.09);
  }
  .mobile-nav {
    // background-color: white !important;
    z-index: 10 !important;
    display: flex !important;
  }
  .mobile-navbar {
    background-color: white !important;
    height: fit-content !important;
    padding: 10px 0;
    width: 100% !important;
    transform: translateX(-100vw) !important;
    transition: all 0.4s ease;
  }
  .mobile-navbar.popin {
    transform: translateX(0) !important;
  }
  .navbar {
    display: none !important;
  }
  .user-nav {
    position: absolute;
    right: -50px !important;
    bottom: -50px !important;
    display: flex;
    z-index: -1;
    transform: scaleX(-1) scale(0.4) translateY(100px);
  }
  .user-balloon {
    z-index: -2;
    transform: scale(0.3) translate(100%, -50px);
    z-index: -2;
  }
  .logo,
  .mobile-nav .logo {
    font-family: "Poiret One", cursive;
    letter-spacing: 0.25em;
  }
}

.header,
.sub-header {
  font-family: "Josefin Sans", sans-serif;
  background-clip: text !important;
  -moz-background-clip: text;
  font-weight: bolder;
  color: transparent;
  background: url("../images/dark.gif");
}
.header {
  text-transform: uppercase;
}
.dots {
  opacity: 0.25;
}
.color-shades {
  width: 30vw;
  height: 100vh;
  display: none;
  grid-template-columns: repeat(3, 1fr);
}
.colorstripe {
  width: 10vw;
  height: 100vh;
}

.home .btn {
  width: 150px !important;
  text-align: center;
}
.prj-btn {
  background-color: var(--link) !important;
  color: #f8f8f8 !important;
  border-color: var(--link) !important ;
}
.prj-btn:hover {
  color: var(--link) !important;
  background-color: transparent !important;
}
.btn-container .btn {
  width: fit-content;
  height: fit-content !important;
  padding: 10px;
  cursor: pointer !important;
}
.notnean-div {
  z-index: 0;
  margin-left: -20px;
}
.header-section {
  z-index: 1 !important;
}
.not-nean {
  width: 500px;
}
.box {
  height: 500px;
  box-shadow: 0px 0px 10px 1px rgba($color: #000000, $alpha: 0.09) !important;
}

.mail-nean {
  transition: all 0.4s ease;
  a {
    transition: all 0.4s ease;
    :hover {
      color: var(--link) !important;
    }
  }
}
.social-handles {
  a {
    transition: all 0.4s ease;
    :hover {
      color: var(--link) !important;
    }
  }
  .sm-icon:hover {
    transform: rotate(15deg);
  }
}
@media (max-width: 768px) {
  .box {
    flex-direction: column-reverse;
    height: fit-content;
    gap: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    .not-nean {
      width: 200px;
      border-radius: 4px;
    }
  }
  .mail-nean * {
    font-size: 16px !important;
  }
}
.balloon {
  max-width: 300px;
}
.tree {
  width: 240px;
}

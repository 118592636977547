.about {
  max-height: 100vh !important;
  min-height: 100vh !important;
  height: 100%;

  overflow-y: hidden !important;
}
.about-box {
  flex: 0.6;
}
.about-container {
  flex: 1;
  background: var(--gray);
  width: 65%;
}
.about-header {
  flex: 0.1;
}
.about-description {
  flex: 0.6;
  text-align: justify;
}
.info-holder {
  flex: 0.8;
}
.image-holder {
  flex: 0.4;
}
.image-holder img {
  object-fit: cover !important;
  width: 100%;
  height: 100%;
}
.icon {
  width: 40px;
}
.skills,
.tools {
  font-size: 14px;
}
.skills:nth-child(2) .icon {
  width: 100px !important;
}
@media (max-width: 768px) {
  .about {
    max-height: 100% !important;
    min-height: 100% !important;
  }
.info-holder{
  padding: 4px !important;
}
  .about-box,
  .about-description,
  .info-holder {
    flex: 1;
    height: 100% !important;
    width: 100% !important;
  }

  .about-container {
    width: 100%;
    flex-direction: column;
    background-color: var(--grayE);
  }
  .about-description {
    flex: 1;
    width: 100% !important;
  }
  .info-holder {
    flex: 1;
    height: 100% !important;
  }
  .image-holder {
    width: 100%;
  }
  .image-holder img {
    max-width: 320px;
  }
  .tree {
    display: none;
  }
}
.skill,
.tool {
  cursor: pointer;
}
.skill:hover .icon,
.tool:hover .icon,
.tech:hover .icon {
  animation: 0.75s rotate alternate infinite;
}
@keyframes rotate {
  100% {
    transform: translateY(3px);
  }
}
.visit-link {
  .visit-icon {
  transition: all 0.4s ease;
  }
  width: 100px;
  border: 2px solid #222;
  padding: 3px;
  &:hover .visit-icon {
    transform: translateX(5px);
  }
}
